<template>
  <div id="confirmationReport">
    <el-dialog
      :title="confirmationReportFormTitle"
      width="1000px"
      :visible.sync="confirmationReportDialogVisible"
      :close-on-click-modal="false"
      @close="confirmationReportDialogClose"
    >
      <el-form
        ref="confirmationReportFormRef"
        :model="confirmationReportForm"
        :rules="confirmationReportFormRules"
        label-position="right"
        label-width="260px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="confirmationReportForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品代号" prop="productNo">
              <el-input v-model="confirmationReportForm.productNo" placeholder="请输入产品代号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最终样品的临床试用和评价，确认最终产品是否满足产品的预期用途和使用要求" prop="confirmationItem1">
              <el-input
                v-model="confirmationReportForm.confirmationItem1"
                placeholder="请输入最终样品的临床试用和评价，确认最终产品是否满足产品的预期用途和使用要求"
                type="textarea"
                :rows="6"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品临床试用时的使用风险分析，连同先前进行的设计风险、过程风险分析，确认这三类风险已降低到可接受程度" prop="confirmationItem2">
              <el-input
                v-model="confirmationReportForm.confirmationItem2"
                placeholder="请输入产品临床试用时的使用风险分析，连同先前进行的设计风险、过程风险分析，确认这三类风险已降低到可接受程度"
                type="textarea"
                :rows="6"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认该产品的产品技术要求，检验接收标准和生产作业规范，并发布和实施" prop="confirmationItem3">
              <el-input
                v-model="confirmationReportForm.confirmationItem3"
                placeholder="请输入确认该产品的产品技术要求，检验接收标准和生产作业规范，并发布和实施"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国内销售的新产品：按国家有关法规进行新产品的注册" prop="confirmationItem4">
              <el-input
                v-model="confirmationReportForm.confirmationItem4"
                placeholder="请输入国内销售的新产品：按国家有关法规进行新产品的注册"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认结果总结" prop="confirmationResults">
              <el-input
                v-model="confirmationReportForm.confirmationResults"
                placeholder="请输入确认结果总结"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批注意见" prop="approval">
              <el-input
                v-model="confirmationReportForm.approval"
                placeholder="请输入批注意见"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设计开发验证负责人" prop="principal">
              <el-input v-model="confirmationReportForm.principal" placeholder="请输入设计开发验证负责人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="confirmationReportForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="验证日期" prop="signatureDate">
              <el-date-picker v-model="confirmationReportForm.signatureDate" placeholder="请选择验证日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期" prop="approverDate">
              <el-date-picker v-model="confirmationReportForm.approverDate" placeholder="请选择批准日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="confirmationReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="confirmationReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="confirmationReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="productNo" label="产品代号" />
      <el-table-column prop="confirmationItem1" label="最终样品的临床试用和评价，确认最终产品是否满足产品的预期用途和使用要求" />
      <el-table-column prop="confirmationItem2" label="产品临床试用时的使用风险分析，连同先前进行的设计风险、过程风险分析，确认这三类风险已降低到可接受程度" />
      <el-table-column prop="confirmationItem3" label="确认该产品的产品技术要求，检验接收标准和生产作业规范，并发布和实施" />
      <el-table-column prop="confirmationItem4" label="国内销售的新产品：按国家有关法规进行新产品的注册" />
      <el-table-column prop="confirmationResults" label="确认结果总结" />
      <el-table-column prop="principal" label="设计开发验证负责人" />
      <el-table-column label="验证日期">
        <template slot-scope="scope">
          <span v-if="scope.row.signatureDate">{{ scope.row.signatureDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approval" label="批注意见" />
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="批准日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approverDate">{{ scope.row.approverDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="confirmationReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addConfirmationReport, deleteConfirmationReport, updateConfirmationReport, selectConfirmationReportInfo, selectConfirmationReportList } from '@/api/technology/confirmationReport'

export default {
  data () {
    return {
      confirmationReportDialogVisible: false,
      confirmationReportFormTitle: '',
      confirmationReportForm: {
        id: '',
        productName: '',
        productNo: '',
        confirmationItem1: '',
        confirmationItem2: '',
        confirmationItem3: '',
        confirmationItem4: '',
        confirmationResults: '',
        principal: '',
        signatureDate: '',
        approval: '',
        approver: '',
        approverDate: ''
      },
      confirmationReportFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      confirmationReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectConfirmationReportList(this.searchForm).then(res => {
      this.confirmationReportPage = res
    })
  },
  methods: {
    confirmationReportDialogClose () {
      this.$refs.confirmationReportFormRef.resetFields()
    },
    confirmationReportFormSubmit () {
      if (this.confirmationReportFormTitle === '设计开发确认报告详情') {
        this.confirmationReportDialogVisible = false
        return
      }
      this.$refs.confirmationReportFormRef.validate(async valid => {
        if (valid) {
          if (this.confirmationReportFormTitle === '新增设计开发确认报告') {
            await addConfirmationReport(this.confirmationReportForm)
          } else if (this.confirmationReportFormTitle === '修改设计开发确认报告') {
            await updateConfirmationReport(this.confirmationReportForm)
          }
          this.confirmationReportPage = await selectConfirmationReportList(this.searchForm)
          this.confirmationReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.confirmationReportFormTitle = '新增设计开发确认报告'
      this.confirmationReportDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteConfirmationReport(row.id)
        if (this.confirmationReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.confirmationReportPage = await selectConfirmationReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.confirmationReportFormTitle = '修改设计开发确认报告'
      this.confirmationReportDialogVisible = true
      this.selectConfirmationReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.confirmationReportFormTitle = '设计开发确认报告详情'
      this.confirmationReportDialogVisible = true
      this.selectConfirmationReportInfoById(row.id)
    },
    selectConfirmationReportInfoById (id) {
      selectConfirmationReportInfo(id).then(res => {
        this.confirmationReportForm.id = res.id
        this.confirmationReportForm.productName = res.productName
        this.confirmationReportForm.productNo = res.productNo
        this.confirmationReportForm.confirmationItem1 = res.confirmationItem1
        this.confirmationReportForm.confirmationItem2 = res.confirmationItem2
        this.confirmationReportForm.confirmationItem3 = res.confirmationItem3
        this.confirmationReportForm.confirmationItem4 = res.confirmationItem4
        this.confirmationReportForm.confirmationResults = res.confirmationResults
        this.confirmationReportForm.principal = res.principal
        this.confirmationReportForm.signatureDate = res.signatureDate
        this.confirmationReportForm.approval = res.approval
        this.confirmationReportForm.approver = res.approver
        this.confirmationReportForm.approverDate = res.approverDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectConfirmationReportList(this.searchForm).then(res => {
        this.confirmationReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectConfirmationReportList(this.searchForm).then(res => {
        this.confirmationReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectConfirmationReportList(this.searchForm).then(res => {
        this.confirmationReportPage = res
      })
    }
  }
}
</script>

<style>
</style>
